<template>
  <div class="investments page">
    <section class="section">
      <MSectionHeader
        class="header"
        title="Ghid pentru Planul Tău Financiar"
        description="Într-o lume instabilă, pregătirea financiară devine o necesitate. Dezvoltarea financiară personală nu înseamnă doar economisire, ci și planificare strategică pentru protecție în vremuri dificile și prosperitate în cele bune. Află cum să-ți construiești un plan financiar care să se potrivească obiectivelor și aspirațiilor tale."
      />
      <!-- <MSteps :steps="characteristics" class="steps" /> -->
      <div class="companyInsurances__container">
        <div class="companyPerks__title">
          <h4>{{ $t("Ce Include") }}</h4>
        </div>
        <SfCarousel
          class="m-product-carousel"
          :settings="carouselSettings"
          :key="characteristics.length"
          :defaultColor="true"
        >
          <SfCarouselItem v-for="(card, i) in characteristics" :key="i">
            <MInfoCardVertical
              :product="card"
              class="companyInsurances__card"
            />
          </SfCarouselItem>
        </SfCarousel>
        <!-- <div class="companyInsurances">
					<MInfoCardVertical
						v-for="(card, i) in companyInsurances"
						:key="i"
						:product="card"
						class="companyInsurances__card"
					/>
				</div> -->
      </div>
    </section>
  </div>
</template>
<script>
import MSectionHeader from "@/components/molecules/m-section-header";
import MInfoCardVertical from "@/components/molecules/m-info-card-vertical";
// import MSteps from "@/components/molecules/m-steps";
import { SfCarousel } from "@storefront-ui/vue";

export default {
  components: {
    MSectionHeader,
    MInfoCardVertical,
    // MSteps,
    SfCarousel,
  },
  data() {
    return {
      carouselSettings: {
        animationDuration: 400,
        rewind: true,
        slidePerPage: false,
        perView: 4,
        peek: {
          before: 0,
          after: 40,
        },
        breakpoints: {
          768: {
            perView: 1,
            peek: {
              before: 30,
              after: 30,
            },
          },
        },
      },
      quote: {
        title: "Use the average cost effect:",
        description:
          "If an investment account's savings rate remains constant over the long term, depending on the fund's price development, fewer shares will be purchased at higher prices and more shares at lower prices. In this way, investors can take advantage of price fluctuations to achieve a cheaper average starting price over the long term than if they regularly buy a fixed number of shares over the same period. This method is particularly recommended for equity fund investments.",
      },
      // characteristics: [
      //   {
      //     title: "Evaluarea Financiară",
      //     description:
      //       "Înainte de orice, este crucial să înțelegem unde ne aflăm. Consultantii nostrii te vor ghida prin procesul de evaluare completă a situației tale financiare, de la venituri și cheltuieli până la activități și datorii.",
      //     image: require("@/assets/icons/save-money.png"),
      //   },
      //   {
      //     title: "Stabilirea Obiectivelor",
      //     description:
      //       "Vom discuta cum să setezi obiective financiare realiste și personalizate, fie că este vorba de economisirea pentru o casă nouă, planificarea pensionării sau investiții pe termen lung.",
      //     image: require("@/assets/icons/deposit.png"),
      //   },
      //   {
      //     title: "Planificarea Investițiilor",
      //     description:
      //       "Alege investițiile potrivite pentru tine. Consultantii nostri vor prezenta diferite opțiuni de investiții și cum să le alegi în funcție de profilul tău de risc și obiectivele financiare.",
      //     image: require("@/assets/icons/provision.png"),
      //   },
      //   {
      //     title: "Gestionarea Riscurilor",
      //     description:
      //       "Importanța asigurărilor și a altor metode de protecție financiară nu poate fi neglijată. Află cum să minimizezi riscurile în planul tău financiar.",
      //     image: require("@/assets/icons/family.png"),
      //   },
      //   {
      //     title: "Optimizarea Fiscală",
      //     description:
      //       "Consultantii nostri te vor  învăța cum să profiți de oportunitățile fiscale pentru a maximiza veniturile și a minimiza taxele.",
      //     image: require("@/assets/icons/family.png"),
      //   },
      //   {
      //     title: "Educație Financiară",
      //     description:
      //       "Îmbunătățește-ți cunoștințele financiare cu resurse și sfaturi care să te ajute să faci alegeri informate.",
      //     image: require("@/assets/icons/family.png"),
      //   },
      // ],
      characteristics: [
        {
          title: "Evaluarea Financiară",
          subtitle: "Înțelegerea situației tale financiare.",
          list: [
            "Analiză detaliată a veniturilor și cheltuielilor.",
            "Evaluare a activelor și datoriilor.",
            "Ghidare în procesul de evaluare completă.",
          ],
        },
        {
          title: "Stabilirea Obiectivelor",
          subtitle: "Setarea obiectivelor financiare.",
          list: [
            "Definirea obiectivelor realiste și personalizate.",
            "Economisire pentru achiziții majore sau pensionare.",
            "Planificare pentru investiții pe termen lung.",
          ],
        },
        {
          title: "Planificarea Investițiilor",
          subtitle: "Selectarea investițiilor potrivite.",
          list: [
            "Prezentarea diferitelor opțiuni de investiții.",
            "Alegerea investițiilor în funcție de profilul de risc.",
            "Alignarea investițiilor cu obiectivele financiare.",
          ],
        },
        {
          title: "Gestionarea Riscurilor",
          subtitle: "Minimizarea riscurilor financiare.",
          list: [
            "Importanța asigurărilor și protecției financiare.",
            "Strategii pentru reducerea riscurilor financiare.",
            "Implementarea unor metode de protecție financiară.",
          ],
        },
        {
          title: "Optimizarea Fiscală",
          subtitle: "Maximizarea veniturilor și minimizarea taxelor.",
          list: [
            "Identificarea oportunităților fiscale.",
            "Ghidare în aplicarea unor strategii fiscale eficiente.",
            "Minimizarea impactului fiscal asupra veniturilor.",
          ],
        },
        {
          title: "Educație Financiară",
          subtitle: "Îmbunătățirea cunoștințelor financiare.",
          list: [
            "Acces la resurse și sfaturi pentru alegeri informate.",
            "Creșterea nivelului de educație financiară.",
            "Dezvoltarea abilităților financiare pentru gestionarea eficientă a banilor.",
          ],
        },
      ],
    };
  },
  mounted() {
    window.scrollTo({ top: 0 });
  },
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.investments {
  padding: 96px var(--spacer-xl);
  margin: 0 auto;
  max-width: 1272px;
  @include for-mobile {
    padding: 84px var(--spacer-sm);
  }
  .header {
    text-align: center;
  }
}
.section {
  padding: var(--spacer-2xl) 0;
}
.steps {
  &::v-deep .perk__content {
    max-width: 14rem;
  }
}
.quote {
  display: block;
  margin: var(--spacer-xl) 0 0;
  padding: 0.75rem var(--spacer-sm);
  border-left: 4px solid var(--c-primary);
  font-family: var(--font-family-secondary);
  font-size: 0.85rem;
  &__title {
    font-weight: var(--font-bold);
    margin: 0 0 var(--spacer-xs);
  }
  &__description {
    font-weight: var(--font-normal);
    margin: 0;
  }
}
.companyInsurances {
  display: flex;
  flex-wrap: wrap;
  &__container {
    margin-top: var(--spacer-2xl);
  }
  &__card {
    margin: var(--spacer-sm);
    flex: 1 1 calc(25% - 2rem - 2px);
    min-height: 10rem;
    // max-width: calc(25% - 2rem - 2px);
    height: 34rem;
  }
}
.companyPerks {
  display: flex;
  flex-wrap: wrap;
  &__container {
    flex: 1 1 40%;
    padding: var(--spacer-sm) 0;
  }
  &__title {
    h4 {
      margin: 0 0 var(--spacer-sm);
      font-family: var(--font-family-secondary);
      font-weight: var(--font-bold);
      font-size: 1.5rem;
      border-bottom: 2px solid var(--c-primary);
      padding: 0 0 var(--spacer-sm);
    }
  }
  &__perk {
    flex: 1 1 calc(50% - 2rem);
    max-width: calc(50% - 2rem);
    padding: var(--spacer-lg) var(--spacer-sm);
  }
  &__sidenote {
    display: block;
    font-family: var(--font-family-secondary);
    font-size: var(--font-xs);
    color: var(--c-gray-lighten);
    padding: var(--spacer-sm);
    text-align: center;
  }
}
</style>
